
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './public-pages/login/login.component';
import { ComplaintComponent } from './public-pages/complaint/complaint.component';
import { RecoveryPasswordComponent } from './public-pages/recovery-password/recovery-password.component';
import { PagesComponent } from './pages/pages.component';
import { FeedbackComponent } from './shared/feedback/feedback.component';
import { SearchComplaintComponent } from './public-pages/search-complaint/search-complaint.component';
import { ResetPasswordComponent } from './public-pages/reset-password/reset-password.component';
import { ResetPasswordResolver } from './public-pages/reset-password/resolver/reset-password-resolver';
import { LoginGuard } from './guards/login.guard';

const appRoutes: Routes = [

    { path: 'login', canActivate: [LoginGuard], component: LoginComponent, data: {title: 'Ingrese'} },
    { path: 'complaint/create', component: ComplaintComponent, data: {title: 'Crear denuncia'} },
    { path: 'recovery-password', component: RecoveryPasswordComponent, data: {title: 'Recuperar contraseña'} },
    { path: 'message/:code', component: FeedbackComponent, data: {title: 'GEPRODI'} },
    { path: 'search-complaint', component: SearchComplaintComponent, data: {title: 'Consulte su queja'} },
    { path: 'reset-password', component: ResetPasswordComponent,
      resolve: { data: ResetPasswordResolver},
      data: {title: 'Recuperar contraseña'} },
    {
        path: '',
        component: PagesComponent,
        canActivate: [LoginGuard],
        loadChildren: './pages/pages.module#PagesModule'
    },
    { path: '**', component: FeedbackComponent }
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash : true });
