import { Injectable } from '@angular/core';
import {WrapperService} from '../wrapper/wrapper.service';
import {Observable} from 'rxjs';
import {EndPoints} from '../../constants/endpoints';
import {TaskDTO} from '../../models/TaskDTO';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private _api: WrapperService) { }

  get(): Observable<any> {
    return this._api.get(EndPoints.TASK);
  }

  create(task: Object): Observable<any> {
    return this._api.post(EndPoints.TASK, task);
  }

  update(id: number, task: Object): Observable<any> {
    return this._api.put(EndPoints.TASK + '/' + id, task);
  }

  delete(task: TaskDTO) {
    return this._api.delete(`${EndPoints.TASK}/${task.id}`);
  }

  commit(task: Object): Observable<any> {
    return this._api.post(EndPoints.TASK+ '/commit', task);
  }

  getcommits(id:number){
    return this._api.get(EndPoints.TASK+'/getcommits/'+id);
  }
}
