import { Injectable, EventEmitter } from '@angular/core';
import { EndPoints } from 'src/app/constants/endpoints';
import { WrapperService } from '../wrapper/wrapper.service';
import { Observable } from 'rxjs';
import { ProcedureDTO } from 'src/app/models/ProcedureDTO';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  createProcedureObs = new EventEmitter<any>()
  createSancionesObs = new EventEmitter<any>()
  updateProcedureObs = new EventEmitter<any>()
  openModalProcedure = new EventEmitter<any>()
  openModalFiles = new EventEmitter<any>()
  openModalDetails = new EventEmitter<any>()
  openModalSanciones = new EventEmitter<any>()
  

  constructor(
    private _api: WrapperService,
    
  ) {}

  createProcedure(procedure: any): Observable<any> {
    return this._api.post(EndPoints.PROCEDURE, procedure);
  }

  uploadFiles(procedure: any): Observable<any> {
    return this._api.post(`${EndPoints.PROCEDURE}/uploadFiles`, procedure);
  }

  updateProcedure(procedure: ProcedureDTO): Observable<any> {
    return this._api.put(`${EndPoints.PROCEDURE}/update/${procedure.id}`, procedure);
  }

  getStates(): Observable<any> {
    return this._api.get(`${EndPoints.STATE}`);
  }

  getStages(state_id: number): Observable<any> {
    return this._api.get(`${EndPoints.STATE}/${state_id}/${EndPoints.STAGE}`);
  }

  getStagesMagister(state_id: number): Observable<any> {
    return this._api.get(`${EndPoints.STATE}/magister/${state_id}/${EndPoints.STAGE}`);
  }

  getSubStages(id: number): Observable<any> {
    return this._api.get(`${EndPoints.STAGE}/${id}/${EndPoints.SUB_STAGE}`);
  }

  changeGestion(id: number){
    return this._api.get(`${EndPoints.CHANGE_PROCEDURE}/${id}`)
  }

  createFolioGeneral(idComplaint: number){
    return this._api.get(`${EndPoints.PROCEDURE}/mergefiles/${idComplaint}`)
  }

  createFolio(idComplaint: number,files){
    return this._api.post(`${EndPoints.PROCEDURE}/createFolio/${idComplaint}`,files)
  }

  changePrivacy(idProcedure: number){
    return this._api.get(`${EndPoints.PROCEDURE}/changePrivacy/${idProcedure}`)
  }

  deleteProcedureWithFiles(idProcedure: number): Observable<any> {
    return this._api.get(`${EndPoints.PROCEDURE}/deleteWithFiles/${idProcedure}`);
  }

  getTracking(idProcedure: number){
    return this._api.get(`${EndPoints.PROCEDURE}/listTracking/${idProcedure}`)
  }

}
