import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageConfig } from 'src/app/util/MessageConfig';
import { Config } from 'src/app/constants/config';
import { LocalStorageTags } from 'src/app/constants/localstorage-tags';
import { StorageService } from 'src/app/services/services.index';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {

  errorMessages: MessageConfig[] = Config.ERROR_MESSAGES;
  message: MessageConfig;

  constructor(
    public route: ActivatedRoute,
    public _storageService: StorageService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.findMessage(params['code']);
    })
  }

  ngOnDestroy() {
    if(this._storageService.getItem(LocalStorageTags.SUCCESS)){
      this._storageService.removeItem(LocalStorageTags.SUCCESS);
    }
  }

  findMessage(code: number): void {

    const successMessage: MessageConfig = this._storageService.getItem(LocalStorageTags.SUCCESS);

    if (successMessage.body) {
      this.message = successMessage;
      return;
    }

    this.message = this.errorMessages.find(x => x.code == code);
    console.log(this.message);
    this.message = !this.message ?
      this.errorMessages.find(x => x.code === 404) : this.message;
  }

}
