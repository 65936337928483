import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {EndPoints} from '../../../constants/endpoints';

@Injectable()
export class ResetPasswordResolver implements Resolve<any> {

  token: string;
  constructor(private readonly authServ: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.token = route.queryParams['token'];
    return this.authServ.validateRequestReset({ token: this.token });
  }
}
