import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RESET_PASSW } from '../../constants/pages';
import { HttpResponse } from '@angular/common/http';
import { HttpStatus } from '../../constants/http-status';
import { MessageConfig } from '../../util/MessageConfig';
import { ResetPasswordDTO } from '../../models/ResetPasswordDTO';
import { CustomValidators } from '../../constants/CustomValidators';
import {LocalStorageTags} from '../../constants/localstorage-tags';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  PAGE_CONST: any;
  fg: FormGroup;
  responseMessage: string;
  resolverResponse: any;
  message: MessageConfig;
  loading = false;
  constructor(private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.resolverResponse = this.actRoute.snapshot.data.data;
    this.handleResolve(this.actRoute.snapshot.data.data);
    this.getTexts();
    this.initForm();
  }

  getTexts() {
    this.PAGE_CONST = RESET_PASSW['es'];
  }

  handleResolve(data: any) {
    switch (data.status) {
      case HttpStatus.NOT_FOUNT:
        this.message = {
          error: false,
          code: 200,
          imgUrl: 'https://dummyimage.com/240x140/bbb/fff',
          heading: 'Link no encontrado',
          subheading: `El enlace no es válido`,
          body: `El link de recuperación que intenta utilizar no es válido, por favor reintentelo.`
        };
        localStorage.setItem(LocalStorageTags.SUCCESS, JSON.stringify(this.message));
        this.router.navigate(['/message/' + HttpStatus.NOT_FOUNT]);
        break;
      case HttpStatus.NO_CONTENT:
        this.message = {
          error: false,
          code: 200,
          imgUrl: 'https://dummyimage.com/240x140/bbb/fff',
          heading: 'Link expirado o usado',
          subheading: `El enlace ya expiró o fue usado`,
          body: `El link que intenta utilizar ya expiró o ha sido usado para recuperar la contraseña.`
        };
        localStorage.setItem(LocalStorageTags.SUCCESS, JSON.stringify(this.message));
        this.router.navigate(['/message/' + HttpStatus.NO_CONTENT]);
        break;
    }
  }

  initForm() {
    this.fg = this.fb.group({
      password: ['', [Validators.minLength(8)]],
      confirm_password: ['', [Validators.minLength(8)]]
    }, { validator: CustomValidators.checkPasswords });
  }

  resetPassword() {
    if (!this.fg.valid) {
      return;
    }
    const resertPasswordDTO = new ResetPasswordDTO(this.fg.get('password').value,
      this.fg.get('confirm_password').value, this.resolverResponse.body);
    this.loading = true;
    this.authService.resetPassword(resertPasswordDTO)
      .subscribe((data: HttpResponse<any>) => {
        this.handleResponse(data);
      });
  }

  handleResponse(data: HttpResponse<any>) {
    switch (data.status) {
      case HttpStatus.OK:
        this.message = {
          error: false,
          code: 200,
          imgUrl: 'https://dummyimage.com/240x140/bbb/fff',
          heading: 'Recuperación de contraseña',
          subheading: `La contraseña fue actualizada`,
          body: `Por favor dirijase al login e ingrese con su nueva clave`,
          ctas: [
            {
              name: 'Ir a login',
              url: '/login',
              type: 'secondary'
            }
          ]
        };
        localStorage.setItem(LocalStorageTags.SUCCESS, JSON.stringify(this.message));
        this.loading = false;
        this.router.navigate(['/message/200']);
        break;
    }
  }
}
