import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    public _authService: AuthService,
    public router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.includes('/login')) {
      if (this._authService.isLogin()) {
        // Se comentarea hasta que se habilite de nuevo el home
        // this.router.navigate(['/dashboard']);
        this.router.navigate(['/complaint']);
      }
      return true;
    }else {
      if (this._authService.isLogin()) {
        return true;
      } else {
        this.router.navigate(['/login']);
      }
    }
  }
}
