import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListToken } from 'src/app/constants/list-token';

@Injectable()

export class AuthInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token: string = localStorage.token;
    let request: HttpRequest<any> = req;
    const rejected: boolean = ListToken.BLACKLIST.some(e => request.url.includes(e));

    if (token && !rejected) {
      token = JSON.parse(token);
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request);
  }

}
