
export const FORGOT_PASSWORD = {
  'es': {
    title: '¿Olvidó su contraseña?',
    message: `Ingrese su dirección
      de correo electrónico a
      continuación y le enviaremos
      un enlace para restablecer su contraseña`,
    placeholder: 'Escriba su email',
    button: 'Restablecer contraseña',
    alreadyAccount: 'Ya tengo una cuenta',
    emailRequired: 'El email es requerido.',
    emailInvalid: 'No es un correo electrónico válido.'
  }
};

export const RESET_PASSW = {
  'es': {
    title: 'Recupere su contraseña',
    message: `Ingrese su nueva contraseña y confírmela`,
    placeholder: 'Escriba su nueva contraseña',
    placeholderConfirm: 'Confirme su nueva contraseña',
    button: 'Guardar contraseña',
    passwordRequired: 'La contraseña es requerida',
    passMinLength: 'La contraseña debe ser de 8 caracteres',
    passwordsNotMatch: 'Las contraseñas no coinciden'
  }
};

export const LOGIN = {
  'es': {
    tilte: 'Bienvenido',
    placeHoldEmail: 'Por favor ingrese el correo',
    placeHoldPassword: 'Por favor escriba su contraseña',
    button: 'Ingresar',
    forgot: '¿Olvidó su contraseña?',
    createComplaint: 'Deseo realizar una denuncia',
    unauthorized: 'El usuario o la contraseña están incorrectos, por favor verifique.',
    nonActiveUser: 'Su usuario no ha sido activado, por favor actívelo.',
    notPermission: 'Aún no hay permisos asignados a su usuario, por favor comuníquese con el administrador del sistema.'
  },
  EMAIL_VERIFIED_SUCCESS: 'success'
}
