import { Component, OnInit } from '@angular/core';
import { LOGIN } from '../../constants/pages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { LoginDTO } from '../../models/LoginDTO';
import { LoginResponseDTO } from '../../models/LoginResponseDTO';
import { HttpResponse } from '@angular/common/http';
import { HttpStatus } from '../../constants/http-status';
import { LocalStorageTags } from '../../constants/localstorage-tags';
import {ActivatedRoute, Router} from '@angular/router';
import { StorageService, ListsService } from 'src/app/services/services.index';
import { EndPoints } from 'src/app/constants/endpoints';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  PAGE_CONSTS: any;
  fg: FormGroup;
  responseMessage = '';
  loading: boolean = false;
  id;

  constructor(
    private fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly _listsService: ListsService,
    private readonly router: Router,
    private readonly activedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getTexts();
    this.initForm();
  }

  getTexts() {
    this.PAGE_CONSTS = LOGIN['es'];
  }

  initForm() {
    this.fg = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  login() {
    if (this.fg.invalid) {
      return;
    }
    this.loading = true;
    const loginDto = new LoginDTO(this.fg.get('email').value, this.fg.get('password').value);

    /*this.authService.login(loginDto).subscribe((datal) => {
      this.id=datal.body.user.id;
    });*/
    const lists = combineLatest(
      
      this.authService.login(loginDto),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_MODULES),
    );

    lists.subscribe(([data, modules]) => {
      this.validateResponse(data, modules);
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  validateResponse(data: HttpResponse<LoginResponseDTO>, modules) {
    if (data.status === HttpStatus.OK) {
      if (data.body.user.permissions.length) {
        const menu = this.filterModules(data.body.user.modules, modules);
        this.authService.saveToLocal(data.body.user.role_id, data.body.user.associated_zone, data.body.token, data.body.user, menu);
        // Cambiar la pantalla que se muestra al inicio luego del login
        // this.router.navigateByUrl('/dashboard');
        if(data.body.user.role_id === 1290){
          this.router.navigateByUrl('/public');
        }else{
          this.router.navigateByUrl('/dashboard');
        }
        
        return;
      }
      this.responseMessage = this.PAGE_CONSTS.notPermission;
    } else if (data.status === HttpStatus.NOT_AUTHORIZED) {
      this.responseMessage = this.PAGE_CONSTS.unauthorized;
    } else if (data.status === HttpStatus.NON_ACTIVE_USER) {
      this.responseMessage = this.PAGE_CONSTS.nonActiveUser;
    }
  }

  filterModules(userModules, modules) {
    let menu = modules.filter((el) => ~userModules.indexOf(el.id));
    menu = menu.map(el => {
      return el = {
        ...el,
        val: `/${el.val}`
      }
    });
    return menu;
  }

  getMenu(userModules, modules) {
    return modules.filter((el) => ~userModules.indexOf(el.id));
  }

  isAnEmailVerification() {
    return this.activedRoute.snapshot.queryParams['email_verification'] &&
      this.activedRoute.snapshot.queryParams['email_verification'] === LOGIN.EMAIL_VERIFIED_SUCCESS;
  }

}
