import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ComplaintProfesionalService } from 'src/app/services/services.index';
import { ProfesionalDTO } from 'src/app/models/ProfesionalDTO';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/constants/config';
import { Util } from 'src/app/util/util';

@Component({
  selector: 'profesional-modal',
  templateUrl: './profesional-modal.component.html',
  styleUrls: ['./profesional-modal.component.scss']
})
export class ProfesionalModalComponent implements OnInit {

  @Input() typeProfesionalList: any;
  @Input() colleges: any;
  @Input() documentTypes: any;
  @Input() lProfesionals: ProfesionalDTO[];

  formProfesional: FormGroup;
  profesionals: ProfesionalDTO[] = [];
  showForm: boolean = false;
  editingProfesional: boolean = false;
  indexEditing: number;
  util = Util;

  constructor(
    public _complaintProfesionalService: ComplaintProfesionalService,
    private _toastr: ToastrService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.formProfesional = this._fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.pattern(Config.REGEX_NUMBER)],
      profesional_type_id: ['', Validators.required],
      document: [null, Validators.maxLength(10)],
      document_type: [''],
      profesional_id: [null],
      email: [null, Validators.email],
      address: [null, Validators.maxLength(100)],
      mobile_phone: [null, Validators.pattern(Config.REGEX_NUMBER)],
      college_id: [''],
    });

    this.getProfesionals();
  }

  getProfesionals() {
    this.profesionals = this._complaintProfesionalService.getProfesionals();
  }

  deleteProfesional(index: number): void {
    if (this._complaintProfesionalService.deleteProfesional(index)) {
      this._toastr.error(`El profesional fue eliminado.`, 'ELIMINADO');
    }
  }

  addProfesional() {
    if (this.formProfesional.invalid) {
      return;
    }

    const profesional = this.createProfesionalObject();

    if (this._complaintProfesionalService.addProfesional(profesional)) {
      this._toastr.success(`El profesional fue agregado.`, 'AGREGADO');
      this.showForm = false;
      this.formProfesional.reset();
    }
  }

  showProfesional(index: number): void {
    this.formProfesional.setValue({
      name: this.profesionals[index].name,
      profesional_type_id: this.profesionals[index].profesional_type_id,
      phone: this.profesionals[index].phone,
      document: this.profesionals[index].document,
      document_type: this.profesionals[index].document_type,
      email: this.profesionals[index].email,
      profesional_id: this.profesionals[index].profesional_id,
      address: this.profesionals[index].address,
      mobile_phone: this.profesionals[index].mobile_phone,
      college_id: this.profesionals[index].college_id
    });
    this.editingProfesional = true;
    this.showForm = true;
    this.indexEditing = index;
  }

  updateProfesional(): void {
    if (this.formProfesional.invalid) {
      return;
    }

    const profesional = this.createProfesionalObject();

    if (this._complaintProfesionalService.updateProfesional(profesional, this.indexEditing)) {
      this._toastr.success(`El profesional fue actualizado.`, 'ACTUALIZADO');
      this.showForm = false;
      this.editingProfesional = false;
      this.formProfesional.reset();
    }
  }

  createProfesionalObject(): ProfesionalDTO {
    return new ProfesionalDTO(
      this.formProfesional.value.name,
      this.formProfesional.value.profesional_type_id,
      this.formProfesional.value.phone,
      this.formProfesional.value.document,
      this.formProfesional.value.document_type,
      this.formProfesional.value.email,
      this.formProfesional.value.profesional_id,
      this.formProfesional.value.address,
      this.formProfesional.value.mobile_phone,
      this.formProfesional.value.college_id
    )
  }

  cancelForm(): void {
    this.showForm = false;
    this.editingProfesional = false
    this.formProfesional.reset();
  }
}
