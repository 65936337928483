import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Services
import { ComplaintProfesionalService, ListsService, ComplaintService, ProcessService, UserService, StorageService, ProcedureService, ReportsService } from './services.index';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [ComplaintProfesionalService, ListsService, ComplaintService, ProcessService, UserService, StorageService, ProcedureService, ReportsService],
  declarations: []
})

export class ServicesModule { }
