import { Injectable } from '@angular/core';
import {WrapperService} from '../wrapper/wrapper.service';
import {EndPoints} from '../../constants/endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private readonly _api: WrapperService) { }

  getReports( startDate: string, endDate: string) {
    return this._api.get(EndPoints.REPORTS + '/' + startDate + '/' + endDate );
  }

  getExpirations() {
    return this._api.get(EndPoints.EXPIRATIONS + "?size=115");
  }
  
  getExpirationsExpired(size: number): Observable<any>  {
    return this._api.get(`expirations?filter=expired&size=${size}`);
  }

  getExpirationsRol25Expired(type: number): Observable<any>  {
    var d =  new Date().toLocaleDateString('en-CA');
    return this._api.get(`complaint/list/expiredatecity/instance/0/${d}/${type}`);
  }

  getExpirationsRol1285Expired(type: number,associatedZone: string): Observable<any>  {
    var d =  new Date().toLocaleDateString('en-CA');
    return this._api.get(`complaint/list/expiredate/city/${associatedZone}/${d}/${type}`);
  }

  getExpirationsExpireSoon(size: number): Observable<any> {
    return this._api.get(`expirations?filter=expiredSoon&size=${size}`);
  }

  getExpirationsAtTime(size: number): Observable<any> {
    return this._api.get(`expirations?filter=atTime&size=${size}`);
  }

  getExpirationsNumbers() {
    return this._api.get(EndPoints.EXPIRATIONS_NUMBERS);
  }

  getExpirationsRol25Numbers() {
    var d =  new Date().toLocaleDateString('en-CA');
    return this._api.get(EndPoints.EXPIRATIONS_NUMBERS_ROL_25+d);
  }
  getExpirationsRol1285Numbers(associatedZone: string) {
    var d =  new Date().toLocaleDateString('en-CA');
    return this._api.get(EndPoints.EXPIRATIONS_NUMBERS_ROL_1285+associatedZone+"/"+d);
  }
}
