import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective {

  @Input('appPermissions') permissionCode: string;
  element: ElementRef;

  constructor(
    el: ElementRef,
    public authService: AuthService
    ) {
    this.element = el;
  }

  ngOnInit() {
    if (!this.authService.permissions.includes(this.permissionCode))
      this.element.nativeElement.remove();
  }

}
