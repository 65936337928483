
export class Buttons {
  public static readonly CLOSE = 'Cerrar';
  public static readonly CANCEL = 'Cancelar';
  public static readonly OK = 'Aceptar';
  public static readonly DELETE = 'Inactivar';
  public static readonly _DELETE = 'Eliminar';
  public static readonly EDITACCESS = 'Editar permisos';
  public static readonly LOGOUT = 'Salir';
  public static readonly ARCHIVE = 'Archivar';
  public static readonly RETURN = 'retornar';
  public static readonly ACTIVE = 'Activar';
}
