import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { TitleComponent } from './title/title.component';
import { ErrorInputComponent } from './error-input/error-input.component';
import { ValidateInputDirective } from '../directives/validate-input.directive';
import { PermissionsDirective } from '../directives/permissions.directive';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FeedbackComponent,
    TitleComponent,
    ErrorInputComponent,
    ValidateInputDirective,
    PermissionsDirective
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FeedbackComponent,
    TitleComponent,
    ErrorInputComponent,
    ValidateInputDirective,
    PermissionsDirective
  ]
})
export class SharedModule { }