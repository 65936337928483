export class LocalStorageTags {
  public static TOKEN = 'token';
  public static SUCCESS = 'success';
  public static USER = {
    role_id: "user-rol",
    associated_zone: "user-zone",
    name: 'user-n',
    modules: 'user-m',
    permissions: 'user-p',
    id: 'id'
  };
}
