import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menu: Array<any>;
  //@ViewChild('sidebarToggle') sidebarToggleButton: ElementRef;

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit() {
    this.menu = this._authService.modules;
    //this.sidebarToggleButton.nativeElement.click();
  }

  
}
