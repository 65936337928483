import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styles: []
})
export class TitleComponent {

  label: string = '';

  constructor(
    private router: Router,
    private title: Title
  ) {
    this.getDataRouter()
      .subscribe(data => {
        this.label = data.title;
        this.title.setTitle(data.title);
      });
  }

  getDataRouter() {
    return this.router.events
      .pipe(filter(event => event instanceof ActivationEnd))
      .pipe(filter((event: ActivationEnd) => event.snapshot.firstChild === null))
      .pipe(map((event: ActivationEnd) => event.snapshot.data))
  }

}
