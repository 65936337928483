import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Config } from 'src/app/constants/config';
import { ComplaintService } from 'src/app/services/services.index';

@Component({
  selector: 'app-search-complaint',
  templateUrl: './search-complaint.component.html',
  styleUrls: ['./search-complaint.component.scss']
})
export class SearchComplaintComponent implements OnInit {

  formSearchComplaint: FormGroup;
  complaint: any;
  empty: boolean = false;
  loading: boolean = false;
  lastProcesalState: string;

  constructor(
    private _fb: FormBuilder,
    public _complaintService: ComplaintService,
  ) { }

  ngOnInit() {
    this.initialSetForm();
  }

  initialSetForm(): void {
    this.formSearchComplaint = this._fb.group({
      business_id: [null, [Validators.required, Validators.pattern(Config.REGEX_NUMBER)]],
    });
  }

  searchComplaint(): void {
    this.empty = false;

    if (this.formSearchComplaint.invalid) {
      return;
    }

    this.loading = true;

    const business_id = this.formSearchComplaint.value.business_id;

    this._complaintService.getComplaint(business_id)
      .subscribe(res => {
        this.loading = false;

        if (res) {
          const sizeProcedures = res.procedures.length;

          this.complaint = res;
          if (sizeProcedures)
            this.lastProcesalState = res.procedures[sizeProcedures - 1].description;
          return;
        }

        this.empty = true;
      });
  }
}
