import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Buttons } from 'src/app/constants/buttons';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userName: string;

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.userName = this.authService.userName;
  }

  logout() {
    Swal.fire({
      title: 'SALIR',
      html: `¿Está seguro de salir?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: Buttons.LOGOUT,
      cancelButtonText: Buttons.CANCEL,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
      }
    });
  }

}
