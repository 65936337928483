import { Component, OnInit } from '@angular/core';
import { FORGOT_PASSWORD } from '../../constants/pages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { RecoverPasswordDTO } from '../../models/RecoverPasswordDTO';
import { HttpResponse } from '@angular/common/http';
import { HttpStatus } from '../../constants/http-status';
import { Router } from '@angular/router';
import { MessageConfig } from '../../util/MessageConfig';
import { StorageService } from 'src/app/services/services.index';
import { LocalStorageTags } from 'src/app/constants/localstorage-tags';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  PAGE_CONST: any;
  fg: FormGroup;
  responseMessage: string;
  successMessage: MessageConfig;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.getTexts();
    this.initForms();
  }

  getTexts() {
    this.PAGE_CONST = FORGOT_PASSWORD['es'];
  }

  initForms() {
    this.fg = this.fb.group({
      email: [null, [Validators.email, Validators.required]]
    });
  }

  resetPassword() {
    if (!this.fg.valid) {
      return;
    }
    this.loading = true;
    this.authService.recoverPassword(new RecoverPasswordDTO(this.fg.get('email').value))
      .subscribe((data: HttpResponse<any>) => {
        this.handleResponse(data);
        this.loading = false;
      });
  }

  handleResponse(data: HttpResponse<any>) {
    switch (data.body.status) {
      case HttpStatus.NO_CONTENT:
        this.responseMessage = data.body.message;
        break;
      case HttpStatus.OK:
        this.successMessage = {
          error: false,
          code: 200,
          imgUrl: 'https://dummyimage.com/240x140/bbb/fff',
          heading: 'Recuperación de contraseña',
          subheading: `Le hemos enviado un correo electrónico`,
          body: `Por favor revise su correo electrónico y recupere su contraseña.`
        }
        this._storageService.setItem(LocalStorageTags.SUCCESS, this.successMessage);
        this.router.navigate(['/message/200']);
        break;
    }
  }
}
