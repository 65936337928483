import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// MODULES
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicesModule } from './services/services.module';
import { PipesModule } from './pipes/pipes.module';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DataTablesModule } from 'angular-datatables';
import { NgxDropzoneModule } from 'ngx-dropzone';

// ROUTES
import { APP_ROUTES } from './app.routes';

// COMPONENTS
import { PagesComponent } from './pages/pages.component';
import { AppComponent } from './app.component';
import { ComponentComponent } from './components/component/component.component';
import { ComplaintComponent } from './public-pages/complaint/complaint.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoginComponent } from './public-pages/login/login.component';
import { RecoveryPasswordComponent } from './public-pages/recovery-password/recovery-password.component';
import { ProfesionalModalComponent } from './public-pages/complaint/profesional-modal/profesional-modal.component';
import { SearchComplaintComponent } from './public-pages/search-complaint/search-complaint.component';
import { FilesModalComponent } from './public-pages/complaint/files-modal/files-modal.component';
import { ResetPasswordComponent } from './public-pages/reset-password/reset-password.component';
import { ResetPasswordResolver } from './public-pages/reset-password/resolver/reset-password-resolver';

// INTERCEPTOR
import { AuthInterceptorService } from './services/auth-interceptor/auth-interceptor.service';

import { registerLocaleData } from '@angular/common';

// importar locales
import localeEsCo from '@angular/common/locales/es';
// import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';

import { NgSelectModule } from '@ng-select/ng-select';

registerLocaleData(localeEsCo, 'es-CO');

@NgModule({
  declarations: [
    PagesComponent,
    AppComponent,
    ComponentComponent,
    ComplaintComponent,
    OrderByPipe,
    LoginComponent,
    RecoveryPasswordComponent,
    ProfesionalModalComponent,
    SearchComplaintComponent,
    FilesModalComponent,
    ResetPasswordComponent,
    // MultiSelectDropdownComponent,
  ],
  imports: [
    CommonModule,
    APP_ROUTES,
    BrowserModule,
    HttpClientModule,
    PipesModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ServicesModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AutocompleteLibModule,
    DataTablesModule.forRoot(),
    NgxDropzoneModule,
    NgSelectModule
  ],
  providers: [
    ResetPasswordResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
