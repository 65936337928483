import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpStatus } from 'src/app/constants/http-status';
import { StorageService } from '../local-storage/storage.service';
import { LocalStorageTags } from 'src/app/constants/localstorage-tags';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class WrapperService {

  baseUrl: string = environment.hostUrl;

  constructor(
    public router: Router,
    private _http: HttpClient,
    private _storageService: StorageService
  ) { }

  get(url: string, params?): Observable<any> {
    return this._http.get(this.baseUrl + url, { params: params }).pipe(catchError((err) => this.handlerError(err)));
  }

  post(url: string, body: Object): Observable<any> {
    return this._http.post(this.baseUrl + url, body).pipe(catchError((err) => this.handlerError(err)));
  }

  put(url: string, body: Object): Observable<any> {
    return this._http.put(this.baseUrl + url, body).pipe(catchError((err) => this.handlerError(err)));
  }

  delete(url: string): Observable<any> {
    return this._http.delete(this.baseUrl + url).pipe(catchError((err) => this.handlerError(err)));
  }

  handlerError(error: HttpErrorResponse) {
    switch (error.status) {
      case HttpStatus.ERR_CONNECTION_REFUSED:
        this.router.navigate(['/message/500']);
        $('.modal').modal('hide');
        break;
      case HttpStatus.INTERNAL_SERVER_ERROR:
        this.router.navigate(['/message/500']);
        $('.modal').modal('hide');
        break;
      case HttpStatus.BAD_REQUEST:
        return throwError(error);
      case HttpStatus.NOT_AUTHORIZED:
        Swal.fire({
          title: 'SESIÓN EXPIRADA',
          text: 'Su sesión expiró, por favor ingrese nuevamente.',
          type: 'warning',
        });
        this.cleanStorage();
        this.router.navigate(['/login']);
        $('.modal').modal('hide');
        break;
      default:
        Swal.fire({
          title: 'ERROR',
          text: error.message,
          type: 'error',
        });
        break;
    }
    return throwError(error);
  }

  cleanStorage() {
    this._storageService.removeItem(LocalStorageTags.TOKEN);
    this._storageService.removeItem(LocalStorageTags.USER.name);
    this._storageService.removeItem(LocalStorageTags.USER.modules);
    this._storageService.removeItem(LocalStorageTags.USER.permissions);
  }
}
