import { Injectable } from '@angular/core';
import { WrapperService } from './wrapper/wrapper.service';
import { LoginDTO } from '../models/LoginDTO';
import { EndPoints } from '../constants/endpoints';
import { RecoverPasswordDTO } from '../models/RecoverPasswordDTO';
import { ResetPasswordDTO } from '../models/ResetPasswordDTO';
import { Observable } from 'rxjs';
import { StorageService } from './local-storage/storage.service';
import { LocalStorageTags } from '../constants/localstorage-tags';
import { Router } from '@angular/router';
import { UserDTO } from '../models/UserDTO';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userName: string;
  token: string;
  idRol: number;
  associatedZone: string;
  modules: Array<any>;
  permissions: Array<any>;

  constructor(
    private readonly _api: WrapperService,
    private _storageService: StorageService,
    public _router: Router
  ) {
    this.loadUserFromLocal()
  }

  login(loginDTO: LoginDTO): Observable<any> {
    return this._api.post(EndPoints.LOGIN, loginDTO);
  }

  recoverPassword(request: RecoverPasswordDTO): Observable<any> {
    return this._api.post(EndPoints.RECOV_PASS, request);
  }

  validateRequestReset(token: Object): Observable<any> {
    return this._api.post(EndPoints.VAL_RESET_PASS, token);
  }

  resetPassword(request: ResetPasswordDTO): Observable<any> {
    return this._api.post(EndPoints.RESET_PASS, request);
  }

  isLogin() {
    return (this._storageService.getItem(LocalStorageTags.USER.name) &&
      this._storageService.getItem(LocalStorageTags.TOKEN).length > 20) ? true : false;
  }

  loadUserFromLocal() {
    if (this._storageService.getItem(LocalStorageTags.TOKEN)) {
      this.token = this._storageService.getItem(LocalStorageTags.TOKEN);
      this.userName = this._storageService.getItem(LocalStorageTags.USER.name);
      this.idRol = this._storageService.getItem(LocalStorageTags.USER.role_id);
      this.associatedZone = this._storageService.getItem(LocalStorageTags.USER.associated_zone);
      this.modules = this._storageService.getItem(LocalStorageTags.USER.modules);
      this.permissions = this._storageService.getItem(LocalStorageTags.USER.permissions);
    } else {
      this.token = '';
      this.userName = null;
      this.modules = [];
      this.permissions = [];
      this.idRol = 0;
      this.associatedZone = '';
    }
  }

  saveToLocal(rol: number, zone: number, token: string, user: UserDTO, menu: any): void {
    this._storageService.setItem(LocalStorageTags.TOKEN, token);
    this._storageService.setItem(LocalStorageTags.USER.role_id, rol);
    this._storageService.setItem(LocalStorageTags.USER.associated_zone, zone);
    this._storageService.setItem(LocalStorageTags.USER.name, user.name);
    this._storageService.setItem(LocalStorageTags.USER.modules, menu);
    this._storageService.setItem(LocalStorageTags.USER.permissions, user.permissions);
    this._storageService.setItem(LocalStorageTags.USER.id, user.id);

    this.token = token;
    this.userName = user.name;
    this.modules = menu;
    this.permissions = user.permissions;
  }

  logout() {
    this.userName = null;
    this.token = '';
    this.modules = [];
    this.permissions = [];
    this.idRol = 0;
    this.associatedZone = '';

    this._storageService.removeItem(LocalStorageTags.TOKEN);
    this._storageService.removeItem(LocalStorageTags.USER.name);
    this._storageService.removeItem(LocalStorageTags.USER.modules);
    this._storageService.removeItem(LocalStorageTags.USER.permissions);
    this._storageService.removeItem(LocalStorageTags.USER.role_id);
    this._storageService.removeItem(LocalStorageTags.USER.associated_zone);

    this._router.navigate(['/login']);
  }
}
