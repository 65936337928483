export { AuthService } from './auth.service';
export { ComplaintService } from './complaint/complaint.service';
export { ProcessService } from './process/process.service';
export { ListsService } from './lists/lists.service';
export { ComplaintProfesionalService } from './complaint-profesional/complaint-profesional.service';
export { UserService } from './user/user.service';
export { StorageService } from './local-storage/storage.service';
export { ProcedureService } from './procedure/procedure.service';
export { ReportsService } from './reports/reports.service';
export { TaskService } from './task/task.service';
