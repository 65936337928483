import { Injectable } from '@angular/core';
import { WrapperService } from '../wrapper/wrapper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ListsService {

  constructor(private readonly api: WrapperService) { }

  getList(url: string): Observable<any> {
    return this.api.get(url);
  }

}