import {ListItem} from './ListItem';

export class ProfesionalDTO {
  constructor(
    public name: string,
    public profesional_type_id: number,
    public phone?: string,
    public document?: string,
    public document_type?: number,
    public email?: string,
    public profesional_id?: string,
    public address?: string,
    public mobile_phone?: string,
    public college_id?: number,
    public college?: ListItem,
    public id?: number
  ) { }
}
