import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  getItem(key: string) {

    let value = localStorage.getItem(key);
    if(!value || value == null || value === 'undefined' ){
      return {};
    }


    return JSON.parse(value);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
