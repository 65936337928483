export class HttpStatus {
  public static readonly ERR_CONNECTION_REFUSED = 0;
  public static readonly INTERNAL_SERVER_ERROR = 500;
  public static readonly OK = 200;
  public static readonly NOT_AUTHORIZED = 401;
  public static readonly BAD_REQUEST = 400;
  public static readonly NO_CONTENT = 204;
  public static readonly NOT_FOUNT = 404;

  public static readonly NON_ACTIVE_USER = 600;
}
