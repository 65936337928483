import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-files-modal',
  templateUrl: './files-modal.component.html',
  styles: [
    '.modal-dialog{ max-width: 1000px}'
  ]
})
export class FilesModalComponent implements OnInit {

  files: File[] = [];
  nombre='';
  nameValido = true;
  @Output() eventFiles: EventEmitter<File[]> = new EventEmitter();

  constructor(
    
    private _toastr: ToastrService
    
    ) { }

  ngOnInit() {
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.eventFiles.emit(this.files);
    this.files.forEach((file, i) => {
      this.nombre = file.name;
      
      if(!this.getParentesisInFileName(this.nombre)){
        if(this.nameValido){
          this.nameValido=false;
        }
      };
      if(!this.getPointsInFileName(this.nombre)){
        if(this.nameValido){
          this.nameValido=false;
        }
      };
    });
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.eventFiles.emit(this.files);
    this.nameValido=true;
    this.files.forEach((file, i) => {
      this.nombre = file.name;
      
      if(!this.getParentesisInFileName(this.nombre)){
        if(this.nameValido){
          this.nameValido=false;
        }
      };
      if(!this.getPointsInFileName(this.nombre)){
        if(this.nameValido){
          this.nameValido=false;
        }
      };
    });
  }

  validateName(){
    this.files.forEach((file, i) => {
      this.nombre = file.name;

      if(!this.getParentesisInFileName(this.nombre)){
        if(this.nameValido){
          this.nameValido=false;
        }
      };
      if(!this.getPointsInFileName(this.nombre)){
        if(this.nameValido){
          this.nameValido=false;
        }
      };
    });
  }
  getParentesisInFileName(filename: string): boolean {
    const index = filename.lastIndexOf('(');
    const index2 = filename.lastIndexOf(')');
    return (index === -1 && index2 === -1);
  }

  getPointsInFileName(filename: string): boolean {
    const puntosEncontrados = (filename.match(/\./g) || []).length;
    return puntosEncontrados === 1;
  }

  showAlert(){
    this._toastr.warning(`Uno o mas archivos contienen paréntesis y/o punto(s) en el nombre.`, 'ARCHIVO INVALIDO');
  }
}
