import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ComplaintProfesionalService, ComplaintService, StorageService} from 'src/app/services/services.index';
import {EndPoints} from 'src/app/constants/endpoints';
import {combineLatest} from 'rxjs';
import {ListsService} from 'src/app/services/lists/lists.service';
import {Config} from 'src/app/constants/config';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageConfig} from 'src/app/util/MessageConfig';
import {LocalStorageTags} from 'src/app/constants/localstorage-tags';
import {ProfesionalDTO} from 'src/app/models/ProfesionalDTO';
import {Location} from '@angular/common';
import {ComplaintDTO} from '../../models/ComplaintDTO';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {

  formComplaint: FormGroup;
  lists: any = {};
  cities: any = [];
  keyword = 'name';
  identificationFromId: any;
  complaintCityId: any;
  lProfesionals:ProfesionalDTO[];
  city: string = '';
  errorCity = false;
  successMessage: MessageConfig;
  loading = false;
  files: File[] = [];
  bussinessId: number;
  formData: FormData = new FormData();
  compId = 0;
  rol_id = this._storageService.getItem(LocalStorageTags.USER.role_id);
  

 

  constructor(
    public _complaintProfesionalService: ComplaintProfesionalService,
    public _complaintService: ComplaintService,
    private readonly _listsService: ListsService,
    private _fb: FormBuilder,
    public router: Router,
    public actRoute: ActivatedRoute,
    private _storageService: StorageService,
    private _location: Location,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getLists();
    this.valitdateInput();
    this.validIfEdit();
    
    console.log(this.rol_id);
  }

  citySelected(cityItem: any, from: string) {
    if (from === 'complaint_city') {
      this.complaintCityId = cityItem;
      this.errorCity = false;
      return;
    }
    this.identificationFromId = cityItem;
  }

  clearCity(from: string) {
    if (from === 'complaint_city') {
      this.complaintCityId = null;
      this.errorCity = true;
      return;
    }
    this.identificationFromId = null;
  }

  getLists() {
    const lists = combineLatest(
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_GENDER),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_TYPE_DOCUMENT),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_SPECIES),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_LIFE_STATUS),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_CITY),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_TYPE_ESTABLISHMENT),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_TYPE_PROFESIONAL),
      this._listsService.getList(EndPoints.LISTS + EndPoints.LIST_COLLEGES)
    );

    lists.subscribe(([gender, typeDocument, species, lifeStatus, cities, typeEstablishment, typeProfesional, colleges]) => {
      this.lists = {
        gender: gender,
        typeDocument: typeDocument,
        species: species,
        cities: cities,
        lifeStatus: lifeStatus,
        typeEstablishment: typeEstablishment,
        typeProfesional: typeProfesional,
        colleges: colleges
      };

      this.cities = this.lists.cities
        .map(({ id, id_list, val, description }) => ({ id: id, id_list: id_list, val: val, name: description }));
    });
  }

  valitdateInput(): void {
    this.formComplaint = this._fb.group({
      anonymous: false,
      name: ['', [Validators.required]],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(Config.REGEX_NUMBER)]],
      gender_id: [''],
      type_id: [''],
      identification: ['', [Validators.maxLength(10), Validators.pattern(Config.REGEX_NUMBER)]],
      affected_name: [''],
      affected_years_old: ['', [Validators.pattern(Config.REGEX_NUMBER)]],
      affected_months_old: ['', Validators.pattern(Config.REGEX_NUMBER)],
      affected_gender_id: [''],
      affected_weight: ['', [Validators.maxLength(3), Validators.pattern(Config.REGEX_NUMBER)]],
      affected_species_id: [''],
      affected_race: [''],
      affected_life_status_id: [''],
      esta_name: [''],
      esta_phone: ['', Validators.pattern(Config.REGEX_NUMBER)],
      esta_address: [''],
      esta_type_establishment_id: [''],
      consejero_ponente: [''],
      acts_date: ['', Validators.required],
      acts: ['', Validators.required],
      documents: [''],
      internal_code: [''],
      terms_and_conditions: [false, Validators.requiredTrue]
    });
  }

  anonymousState() {
    const anonymous = this.formComplaint.controls.anonymous.value;
    if (anonymous) {
      this.formComplaint.get('name').setValidators(Validators.required);
      this.formComplaint.get('last_name').setValidators(Validators.required);
    } else {
      this.formComplaint.get('name').clearValidators();
      this.formComplaint.get('last_name').clearValidators();
      this.formComplaint.controls['name'].setValue('');
      this.formComplaint.controls['last_name'].setValue('');
    }
    this.formComplaint.get('name').updateValueAndValidity();
    this.formComplaint.get('last_name').updateValueAndValidity();
  }

  registerComplaint(): void {
    if (!this.complaintCityId && this.formComplaint.invalid) {
      this.errorCity = true;
      return;
    }

    if (!this.complaintCityId) {
      this.errorCity = true;
      return;
    }

    if (this.formComplaint.invalid) {
      return;
    }

    this.loading = true;
    this.errorCity = false;
    this.toFormData();
    if (this.compId != 0) {
      this._complaintService.updateComplaint(this.compId, this.formData)
        .subscribe( (res) => {
          this._toastr.success(`La queja fue actualizada.`, 'ACTUALIZADO');
          this.router.navigate(['/complaint']);
        }, err => {
          console.error(err);
          this.loading = false;
          this.router.navigate(['/message/400']);
        });
      return;
    }
    this._complaintService.createComplaint(this.formData)
      .subscribe(res => {
        this.bussinessId = res.business_id;
        this._complaintProfesionalService.profesionals = [];
        this.successScreen();
      }, err => {
        console.error(err);
        this.loading = false;
        this.router.navigate(['/message/400']);
      });
  }

  toFormData() {
    for (const key of Object.keys(this.formComplaint.controls)) {
      const value = this.formComplaint.controls[key].value;
      if (key.includes('esta_')) {
        const newKey = key.replace('esta_', '');
        this.formData.append(`establishment[${newKey}]`, value);
      }else if (key.includes('type_id')){
        value.id === undefined ? this.formData.append(key, '') : this.formData.append(key, value.id);
      }else if ((key === 'name' || key === 'last_name') && this.formComplaint.controls.anonymous.value) {
        continue;
      } else {
        this.formData.append(key, value);
      }
    }
    this.formData.append('city_id', this.complaintCityId ? this.complaintCityId.id.toString() : '');
    this.formData.append('identification_from_id', this.identificationFromId ? this.identificationFromId.id.toString() : '');
    this.profesionalsToFormData();
    this.filesToFormData();
  }

  profesionalsToFormData() {
    const profesionals: ProfesionalDTO[] = this._complaintProfesionalService.getProfesionals();
    if (!profesionals.length) {
      return;
    }

    profesionals.forEach((el, i) => {
      for (const key of Object.keys(el)) {
        this.formData.append(`profesionals[${i}][${key}]`, el[key]);
      }
    });

    this._complaintProfesionalService.clearProfesionals();
  }

  filesToFormData() {
    if (!this.files.length) {
      return;
    }

    this.files.forEach((file, i) => {
      this.formData.append(`attachments[${i}]`, file, file.name);
    });
  }

  successScreen() {
    this.successMessage = {
      error: false,
      code: 200,
      imgUrl: 'https://dummyimage.com/240x140/bbb/fff',
      heading: 'Registro exitoso',
      subheading: `El Tribunal Nacional de Ética Profesional, ha recibido su solicitud identificada con el número: ${this.bussinessId}.`,
      body: `A partir de la fecha iniciaremos el proceso de investigación, y le comunicaremos el trámite de la misma. Para más información...`,
      ctas: [
        {
          name: 'Ir a login',
          url: '/login',
          type: 'primary'
        },
        {
          name: 'Crear una nueva queja',
          url: '/complaint/create',
          type: 'info'
        }
      ]
    };
    this._storageService.setItem(LocalStorageTags.SUCCESS, this.successMessage);
    this.loading = false;
    this.router.navigate(['/message/200']);
    
  }

  validIfEdit() {
    this.compId = this.actRoute.snapshot.queryParams['id'];
    if (this.compId > 0) {
      this._complaintService.getComplaintDetail(this.compId)
        .subscribe((comp) => {
          this.initFormToEdit(comp);
          //console.log(comp)
        });
    } else {
      this.compId = 0;
    }
  }

  initFormToEdit(comp: ComplaintDTO) {

    this.formComplaint.controls['name'].setValue(comp.name);
    // this.formComplaint.controls['internal_code'].setValue('la la la la la la');
    this.formComplaint.controls['last_name'].setValue(comp.last_name);
    if (comp.name === 'Anónimo') {
      this.anonymousState();
      this.formComplaint.controls['anonymous'].setValue(true);
    }
    this.formComplaint.controls['email'].setValue(comp.email);
    this.formComplaint.controls['phone'].setValue(comp.phone);
    if (comp.gender_id) {
      this.formComplaint.controls['gender_id'].setValue(comp.gender_id);
    }
    if (comp.type_id) {
      this.formComplaint.controls['type_id'].setValue(comp.type_id);
    }
    if (comp.internal_code) {
      this.formComplaint.controls['internal_code'].setValue(comp.internal_code);
    }
    if (comp.identification) {
      this.formComplaint.controls['identification'].setValue(comp.identification);
    }
    if (comp.affected_name) {
      this.formComplaint.controls['affected_name'].setValue(comp.affected_name);
    }
    if (comp.affected_years_old) {
      this.formComplaint.controls['affected_years_old'].setValue(comp.affected_years_old);
    }
    if (comp.affected_months_old) {
      this.formComplaint.controls['affected_months_old'].setValue(comp.affected_months_old);
    }
    if (comp.affected_gender_id) {
      this.formComplaint.controls['affected_gender_id'].setValue(comp.affected_gender_id);
    }
    if (comp.affected_species_id) {
      this.formComplaint.controls['affected_species_id'].setValue(comp.affected_species_id);
    }
    if (comp.affected_race) {
      this.formComplaint.controls['affected_race'].setValue(comp.affected_race);
    }
    if (comp.affected_weight) {
      this.formComplaint.controls['affected_weight'].setValue(comp.affected_weight);
    }
    if (comp.affected_life_status_id) {
      this.formComplaint.controls['affected_life_status_id'].setValue(comp.affected_life_status_id);
    }

    if (comp.establishment) {
      this.formComplaint.controls['esta_name'].setValue(comp.establishment.name);
      if(comp.establishment.phone==='Por asignar')
      this.formComplaint.controls['esta_phone'].setValue('');
      this.formComplaint.controls['esta_address'].setValue(comp.establishment.address);
      this.formComplaint.controls['esta_type_establishment_id'].setValue(comp.establishment.type_establishment_id);
    }
    if (comp.consejero_ponente) {
      this.formComplaint.controls['consejero_ponente'].setValue(comp.consejero_ponente);
    }

    if(comp.profesionals){
      this.lProfesionals = comp.profesionals;
    }
    this.formComplaint.controls['acts_date'].setValue(comp.acts_date);
    this.formComplaint.controls['acts'].setValue(comp.acts);
    this.citySelected(comp.city, 'complaint_city');
    this.city = this.complaintCityId === null || this.complaintCityId === undefined ? '' : this.complaintCityId.description;
    // this.formComplaint.controls['documents'].setValue(comp.);
    this.formComplaint.controls['terms_and_conditions'].setValue(true);
  }

  goBack() {
    this._complaintProfesionalService.clearProfesionals();
    this.valitdateInput();
    this._location.back();
  }
}
