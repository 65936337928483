import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfesionalDTO } from 'src/app/models/ProfesionalDTO';
import { EndPoints } from 'src/app/constants/endpoints';
import { WrapperService } from '../wrapper/wrapper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComplaintProfesionalService {

  openModalProfesional = new EventEmitter<any>();
  profesionals: ProfesionalDTO[] = [];
  updateProfesionalObs = new EventEmitter<any>()

  constructor(
    private _api: WrapperService
  ) {}

  getProfesionals() {
    return this.profesionals;
  }

  clearProfesionals() {
    return this.profesionals = [];
  }

  addProfesional(profesional: ProfesionalDTO): boolean {
    this.profesionals.push(profesional);
    return true;
  }

  deleteProfesional(index: number): boolean {
    this.profesionals.splice(index, 1);
    return true;
  }

  updateProfesional(profesional:ProfesionalDTO, index: number){
    this.profesionals.splice(index, 1, profesional);
    return true;
  }

  updateProfesional2(profesional: any): Observable<any> {
    
    return this._api.put(`${EndPoints.COMPLAINT}/updateProfesional/${profesional.id}`, profesional);
    
    
  }
}
