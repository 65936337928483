import { Injectable } from '@angular/core';
import { EndPoints } from 'src/app/constants/endpoints';
import { WrapperService } from '../wrapper/wrapper.service';
import { Observable } from 'rxjs';
import { UserDTO } from 'src/app/models/UserDTO';
import { UserConsultaDTO } from 'src/app/models/UserConsultaDTO';
import { PermissionDTO } from '../../models/PermissionDTO';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _api: WrapperService,
  ) { }

  createUser(body: UserDTO): Observable<any> {
    return this._api.post(EndPoints.USERS, body);
  }

  getUsers(): Observable<any> {
    return this._api.get(EndPoints.USERS);
  }

  updateUser(user: UserDTO): Observable<any> {
    return this._api.put(`${EndPoints.USERS}/${user.id}`, user);
  }

  deleteUser(id: number): Observable<any> {
    return this._api.delete(`${EndPoints.USERS}/${id}`);
  }

  activeUser(id: number): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.ACTIVE}/${id}`);
  }

  getUserAccess(id: number): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.PERMISSIONS}/${id}`);
  }

  updateUserAccess(id: number, userAccess: PermissionDTO[]): Observable<any> {
    return this._api.put(`${EndPoints.USERS}/${EndPoints.PERMISSIONS}/${id}`, userAccess);
  }

  createUserConsulta(body: UserConsultaDTO): Observable<any> {
    return this._api.post(`${EndPoints.USERS}/${EndPoints.EXTERNALUSER}`, body);
  }

  getExternalUsersByComplaint(complaint: number): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.USERSCOMPLAINTS}/${complaint}`);
  }
  
  getFirstInstanceMagister(): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.LIST_MAGISTERS}`);
  }

  getLawyerSustanciador(): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.LIST_LAWYER_SUSTAN}`);
  }

  getSustanciadores(): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.LIST_SUSTANCIADORES}`);
  }

  getActiveLawyerByComplaint(complaint: number): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.GET_ACTIVE_LAWYER}/${complaint}`);
  }

  inactiveSustanciador(dto : any){
    return this._api.put(EndPoints.USERSCOMPLAINTS+'/inactive/sustanciador',dto);
  }
  activeSustanciador(dto : any){
    return this._api.put(EndPoints.USERSCOMPLAINTS+'/active/sustanciador',dto);
  }


  getParticipants(): Observable<any> {
    return this._api.get(`${EndPoints.USERS}/${EndPoints.LIST_PARTICIPANTS}`);
  }


  /*getUserByRole(roles: string[]) {
    let params = new HttpParams();
    params = params.append('roles', roles.join(', '));
    return this._api.get(EndPoints.USERS + EndPoints.ROLE, params);
  }*/

}
