import {FormGroup} from '@angular/forms';

export class CustomValidators {

  public static checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirm_password').value;
    return pass === confirmPass ? null : { notSame: true }
  }
}
