import { MessageConfig } from "../util/MessageConfig";

export class Config {
  public static readonly REGEX_NUMBER = '^[0-9]+(\\.[0-9][0-9]?)?';
  public static readonly ERROR_MESSAGES: MessageConfig[] = [
    {
      code: 500,
      heading: '¡Algo salió mal!',
      body: 'Ocurrió un problema, por favor intente más tarde.',
      error: true,
      ctas: [
        {
          name: 'Volver al portal',
          url: '/login'
        }
      ]
    },
    {
      code: 401,
      heading: 'No autorizado',
      body: 'No se encuentra autorizado para acceder, por favor intente con otro usuario.',
      error: true,
      ctas: [
        {
          name: 'Ir a login',
          url: '/login'
        }
      ]
    },
    {
      code: 400,
      heading: 'Contenido errado',
      body: 'Algo dentro de la información que intenta enviar se encuentra errado, por favor contáctenos.',
      error: true,
      ctas: [
        {
          name: 'Ir a login',
          url: '/login'
        }
      ]
    },
    {
      code: 404,
      heading: 'Recurso no encontrado',
      body: 'La url que intenta acceder no fue encontrada.',
      error: true,
      ctas: [
        {
          name: 'Ir a login',
          url: '/login'
        }
      ]
    },
  ];
  public static readonly DTOPTIONS: DataTables.Settings = {
    pagingType: 'simple_numbers',
    pageLength: 10,
    processing: true,
    order: [[ 0, "desc" ]],
    language: {
      "zeroRecords": "No hemos encontrado ninguno registrado",
      "info": "Página _PAGE_ de _PAGES_",
      "infoEmpty": "No existe ningún elemento",
      "infoFiltered": "(filtrado de _MAX_ elementos totales)",
      "search": "Buscar:",
      "paginate": {
        "first": "",
        "last": "",
        "next": ">",
        "previous": "<"
      },
    }
  };


  public static readonly BARCHAR_CONFIG = {
    barChartOptions: {
      responsive: true,
    },
    barChartType: 'bar',
    barChartLegend: true,
    barChartPlugins: [],
    barChartColorDefault: [
      {
        backgroundColor: 'rgba(77,83,255,0.3)'
      }
    ]
  }
}
